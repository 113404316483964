import React, { useEffect } from 'react';
import { useNavigate } from "react-router";
import { useUserAuth } from "../context/UserAuthContext";
import axios, {head} from 'axios';
import CryptoJS from 'crypto-js';
const SlackAuth = () => {
    const { logOut, user } = useUserAuth();
    const navigate = useNavigate();

    function getHubSpotId(access_token){
        let account_info_url = "https://api.hubapi.com/account-info/v3/details"
        let headers = {"content-type": "application/json", "authorization": "Bearer " + access_token}

        fetch(account_info_url, {method: 'POST', headers: headers}).then( response => {
            let portal_id = response.json()['portalId']
            return portal_id;
        });
        return null
    }

    useEffect(() => {
        const oauthCode = new URLSearchParams(window.location.search).get('code');

        const params = new URLSearchParams(window.location.search);
        const returnedState = params.get('state');
        const decodedState = JSON.parse(atob(returnedState));
        const accountToken = decodedState['accountToken'];
        const channelId = decodedState['channelId'];
        const inboxId = decodedState['inboxId'];
        const userId = decodedState['userId'];
        const portalId = decodedState['portalId'];
        const redirectUri = decodeURIComponent(decodedState['redirectUrl']);
        if (oauthCode) {
            console.log("Oauth code known");
            console.log(oauthCode);
            const formData = {
                'oauthCode': oauthCode,
                "portalId": portalId
            };

            const postOAuthCode = async () => {
                console.log("Starting Promise");
                try {
                    console.log("Running Promise");

                    console.log(formData);
                    let token = user.accessToken;
                    fetch('https://slack-helpdesk-api-ajytcbqb.wl.gateway.dev/slack-oauth', {
                        method: 'POST',
                        body: JSON.stringify(formData),
                        headers: {
                            'Authorization': 'Bearer ' + token,
                            'Content-Type': 'application/json'
                        }
                    }).then(function(response) {
                        return response.json();
                    }).then(function(data) {

                        let channelData = {
                            "accountToken": accountToken,
                            "channelId": channelId,
                            "portalId": portalId,
                            "userId": userId,
                            "inboxId": inboxId
                        };
                        console.log(channelData);
                        fetch(`https://slack-helpdesk-api-ajytcbqb.wl.gateway.dev/connect-channel`, {
                            method: 'POST',
                            body: JSON.stringify(channelData),
                            headers: {
                                'Authorization': 'Bearer ' + token,
                                'Content-Type': 'application/json'
                            }
                        }).then(function(response) {
                            return response.json();
                        }).then(function(data) {
                            let slackUserId = data['userId']
                            window.location.href = redirectUri;
                        });
                    });
                    // Handle the returned tokens


                    return token;

                } catch (error) {
                    console.log("Not Working");
                    console.error(error); // Log the error to understand what went wrong
                    return null
                }
            };
            let tokenData = postOAuthCode();

        } else{
            console.log("No Code Found");
        }
    }, []);
    const handleLogout = async () => {
        try {
            await logOut();
            navigate("/");
        } catch (error) {
            console.log(error.message);
        }
    };

    return (
        <>
            <div>
                <h1>
                    You are installing Slack...
                </h1>
            </div>
        </>
    );
};

export default SlackAuth;
import React, {useEffect} from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router";
import { useUserAuth } from "../context/UserAuthContext";
import hubspotLogo from '../img/hubspot.png';
import slackLogo from '../img/slack.png';

const SlackInitialize = () => {
    const { logOut, user } = useUserAuth();
    const navigate = useNavigate();

    const redirectUri = `https://channel.erabond.io/auth-slack-success`;
    // Serialize query params and encode them as part of state


    const handleLogout = async () => {
        try {
            await logOut();
            navigate("/");
        } catch (error) {
            console.log(error.message);
        }
    };
    const initiateOAuthFlow = () => {
        let queryParams = new URLSearchParams(window.location.search);
        let paramsObject = Object.fromEntries(queryParams.entries());
        console.log(queryParams);
        let state = btoa(JSON.stringify(paramsObject)); // Encode as base64 for simplicity
        console.log(state);
        let installLink = `https://slack.com/oauth/v2/authorize?scope=channels%3Ahistory%2Cchannels%3Aread%2Cchat%3Awrite%2Cchat%3Awrite.customize%2Cgroups%3Ahistory%2Cim%3Ahistory%2Cusers%3Aread%2Cusers%3Aread.email&user_scope=chat%3Awrite%2Cim%3Awrite%2Cmpim%3Awrite&redirect_uri=${encodeURIComponent(redirectUri)}&client_id=284518000688.7786910352512&state=${state}`;

        // Redirect to OAuth provider
        window.location.href = installLink;
    };
    return (
        <>
            <div style={{backgroundColor:"white", borderRadius: "8px"}}>
                <div style={{display: "flex", flexDirection: "column", color: "black", padding: "48px"}}>
                    <img src={slackLogo} width={200}
                         style={{textAlign: "center", marginBottom: "24px", alignContent: "center"}}></img>
                    <h3>Connect Slack Account</h3>
                    <p>In order to access your Slack data, we will need you to connect the relevant permissions.</p>
                    <Button style={{backgroundColor: "#825ee7", color: "white"}} variant="primary" onClick={initiateOAuthFlow}>Connect Slack</Button>
                </div>
            </div>

        </>
    );
};

export default SlackInitialize;
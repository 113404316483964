// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBf7E2Ul0cPvLpFnc7q5MM5HZCXQ05QaBs",
    authDomain: "slack-integration-436115.firebaseapp.com",
    databaseURL: "https://slack-integration-436115-default-rtdb.firebaseio.com",
    projectId: "slack-integration-436115",
    storageBucket: "slack-integration-436115.appspot.com",
    messagingSenderId: "827105639267",
    appId: "1:827105639267:web:0127a499deddfc3ad3f156"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
// Initialize Firebase services
export const auth = getAuth(firebaseApp); // For Firebase Authentication
export const db = getFirestore(firebaseApp); // For Firestore database

// Optionally, export the firebaseApp instance if needed
export default firebaseApp;
import { Container, Row, Col } from "react-bootstrap";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./components/Home";
import Login from "./components/Login";
import Signup from "./components/Signup";
import ProtectedRoute from "./components/ProtectedRoute";
import { UserAuthContextProvider } from "./context/UserAuthContext";
import PrimarySearchAppBar from "./components/PrimarySearchAppBar";
import React from "react";
import HubSpotAuth from "./components/HubSpotAuth";
import AuthSuccess from "./components/AuthSuccess";
import SlackInitialize from "./components/SlackInitialize";
import SlackAuth from "./components/SlackAuth";


function App() {
    return (
        <>

        <Container className="appContainer">
            <Row>
                <Col>

                    <UserAuthContextProvider>
                        <Routes>
                            <Route
                                path="/auth"
                                element={
                                    <ProtectedRoute>
                                        <Home />
                                    </ProtectedRoute>
                                }
                            />
                            <Route path="/" element={<Login />} />
                            <Route path="/signup" element={<Signup />} />
                            <Route path="/auth-hubspot" element={
                                <ProtectedRoute>
                                    <HubSpotAuth/>
                                </ProtectedRoute>
                                }
                            />
                            <Route path="/auth-success" element={
                                <ProtectedRoute>
                                    <AuthSuccess/>
                                </ProtectedRoute>
                            }/>
                            <Route path="/auth-slack" element={<SlackInitialize/>}/>
                            <Route path="/auth-slack-success" element={
                                <SlackAuth/>
                            }/>
                        </Routes>
                    </UserAuthContextProvider>
                </Col>
            </Row>
        </Container>
            </>
    );
}

export default App;
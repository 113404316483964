import React, { useEffect } from 'react';
import { useNavigate } from "react-router";
import { useUserAuth } from "../context/UserAuthContext";
import axios, {head} from 'axios';
import CryptoJS from 'crypto-js';
const HubSpotAuth = () => {
    const { logOut, user } = useUserAuth();
    const navigate = useNavigate();
    let CLIENT_ID = process.env.CLIENT_ID;
    let CLIENT_SECRET = process.env.CLIENT_SECRET;
    let REDIRECT_URI = process.env.REDIRECT_URI;

    function getHubSpotId(access_token){
        let account_info_url = "https://api.hubapi.com/account-info/v3/details"
        let headers = {"content-type": "application/json", "authorization": "Bearer " + access_token}

        fetch(account_info_url, {method: 'POST', headers: headers}).then( response => {
            let portal_id = response.json()['portalId']
            return portal_id;
        });
        return null
    }

    useEffect(() => {
        const oauthCode = new URLSearchParams(window.location.search).get('code');

        if (oauthCode) {
            console.log("Oauth code known");
            console.log(oauthCode);
            const formData = {
                'oauthCode': oauthCode
            };

            const postOAuthCode = async () => {
                console.log("Starting Promise");
                try {
                    console.log("Running Promise");

                    console.log(formData);
                    let token = user.accessToken;
                    fetch('https://slack-helpdesk-api-ajytcbqb.wl.gateway.dev/oauth', {
                        method: 'POST',
                        body: JSON.stringify(formData),
                        headers: {
                            'Authorization': 'Bearer ' + token,
                            'Content-Type': 'application/json'
                        }
                    }).then(function(response) {
                        return response.json();
                    }).then(function(data) {
                        console.log(data)
                        let portalId = data['portal_id'];  // Assuming portalId is fetched from data
                        let inboxUrl = `https://app-eu1.hubspot.com/live-messages-settings/${portalId}/inboxes/`;
                        window.location.href = inboxUrl;
                    });
                    // Handle the returned tokens


                    return token;

                } catch (error) {
                    console.log("Not Working");
                    console.error(error); // Log the error to understand what went wrong
                    return null
                }
            };
            let tokenData = postOAuthCode();

        } else{
            console.log("No Code Found");
        }
    }, []);
    const handleLogout = async () => {
        try {
            await logOut();
            navigate("/");
        } catch (error) {
            console.log(error.message);
        }
    };

    return (
        <>
            <div>
                <h1>
                    You are installing HubSpot...
                </h1>
            </div>
        </>
    );
};

export default HubSpotAuth;